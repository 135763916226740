import { AUTH, ROUTE } from '@/constants';
import { loadView } from '../index';

const CertifiedTrainingCourseRouter = loadView(
  'certified-training/CertifiedTrainingCourseRouter',
);
const CertifiedTrainingConclusionView = loadView(
  'certified-training/CertifiedTrainingConclusionView',
);
const CertifiedTrainingCourseDashboardView = loadView(
  'certified-training/course/CertifiedTrainingCourseDashboardView',
);
const CertifiedTrainingCourseLessonView = loadView(
  'certified-training/course/CertifiedTrainingCourseLessonView',
);
const CertifiedTrainingCourseQuizView = loadView(
  'certified-training/course/CertifiedTrainingCourseQuizView',
);
const CertifiedTrainingCourseResultView = loadView(
  'certified-training/course/CertifiedTrainingCourseResultView',
);
const CertifiedTrainingDashboardView = loadView(
  'certified-training/CertifiedTrainingDashboardView',
);

const certifiedTrainingRoute = {
  path: ROUTE.PATH.CERTIFIED_TRAINING,
  component: () => import('@/layouts/default/DefaultLayout.vue'),
  meta: {
    auth: AUTH.USER_ROLE.AUTHORIZED,
  },
  children: [
    {
      path: '',
      name: ROUTE.NAME.CERTIFIED_TRAINING,
      component: CertifiedTrainingDashboardView,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
      },
    },
    {
      path: ROUTE.PATH.CERTIFIED_TRAINING_CONCLUSION,
      name: ROUTE.NAME.CERTIFIED_TRAINING_CONCLUSION,
      component: CertifiedTrainingConclusionView,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
      },
    },
    {
      path: ROUTE.PATH.CERTIFIED_TRAINING_COURSE,
      component: CertifiedTrainingCourseRouter,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
      },
      children: [
        {
          path: '',
          name: ROUTE.NAME.CERTIFIED_TRAINING_COURSE,
          component: CertifiedTrainingCourseDashboardView,
          props: (route) => ({ ...route.params }),
          meta: {
            auth: AUTH.USER_ROLE.AUTHORIZED,
          },
        },
        {
          path: ROUTE.PATH.CERTIFIED_TRAINING_COURSE_DASHBOARD,
          name: ROUTE.NAME.CERTIFIED_TRAINING_COURSE_DASHBOARD,
          component: CertifiedTrainingCourseDashboardView,
          props: (route) => ({ ...route.params }),
          meta: {
            auth: AUTH.USER_ROLE.AUTHORIZED,
          },
        },
        {
          path: ROUTE.PATH.CERTIFIED_TRAINING_COURSE_LESSON,
          name: ROUTE.NAME.CERTIFIED_TRAINING_COURSE_LESSON,
          component: CertifiedTrainingCourseLessonView,
          props: (route) => ({ ...route.params }),
          meta: {
            auth: AUTH.USER_ROLE.AUTHORIZED,
          },
        },
        {
          path: ROUTE.PATH.CERTIFIED_TRAINING_COURSE_QUIZ,
          name: ROUTE.NAME.CERTIFIED_TRAINING_COURSE_QUIZ,
          component: CertifiedTrainingCourseQuizView,
          props: (route) => ({ ...route.params, ...route.query }),
          meta: {
            auth: AUTH.USER_ROLE.AUTHORIZED,
          },
        },
        {
          path: ROUTE.PATH.CERTIFIED_TRAINING_COURSE_RESULT,
          name: ROUTE.NAME.CERTIFIED_TRAINING_COURSE_RESULT,
          component: CertifiedTrainingCourseResultView,
          props: (route) => ({ ...route.params, ...route.query }),
          meta: {
            auth: AUTH.USER_ROLE.AUTHORIZED,
          },
        },
      ],
    },
    {
      path: ROUTE.PATH.CERTIFIED_TRAINING_DASHBOARD,
      name: ROUTE.NAME.CERTIFIED_TRAINING_DASHBOARD,
      component: CertifiedTrainingDashboardView,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
      },
    },
  ],
};

export default certifiedTrainingRoute;
